// import Script from "next/script";
// import { useEffect } from "react";
import { useEffect } from "react";

const VDOAdsScript = () => {
  useEffect(() => {
    const loadVDOAdsScript = () => {
      if (!document.getElementById("vdo-ai-script")) {
        console.log("Loading vdo.ai ads script");

        const script = document.createElement("script");
        script.defer = true;
        script.async = true;
        script.src = `${window.location.protocol}//a.vdo.ai/core/v-newstak-in-v1/vdo.ai.js`;
        script.id = "vdo-ai-script";
        document.head.appendChild(script);
      } else {
        console.log("vdo.ai script already loaded");
      }
    };

    // Delay loading by 3 seconds after the first scroll event
    const handleScroll = () => {
      console.log("Triggering vdo.ai ads script loading after scroll");
      setTimeout(loadVDOAdsScript, 500); // 2-second delay after scroll
      window.removeEventListener("scroll", handleScroll);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div id="v-newstak-in-v1"></div>
    </div>
  );
};

export default VDOAdsScript;


// const VDOAdsScript = () => {
//   // useEffect(() => {
//   //   const loadScript = () => {
//   //     if (document.querySelectorAll('[id^="vdo_logo_parent"]')?.length === 0) {
//   //       console.log("refresh vdo.ai ads");

//   //       // Remove existing script if it exists
//   //       const existingScript = document.getElementById("vdo-ai-script");
//   //       if (existingScript) {
//   //         existingScript.remove();
//   //       }

//   //       // Create and append new script
//   //       const script = document.createElement("script");
//   //       script.id = "vdo-ai-script"; // Set ID for the script
//   //       script.defer = true;
//   //       script.async = true;
//   //       script.src =
//   //         window.location.protocol + "//a.vdo.ai/core/v-mumbaitak-in/vdo.ai.js";
//   //       document.head.appendChild(script);
//   //     }
//   //   };

//   //   // Load script after 400ms and 1000ms
//   //   setTimeout(loadScript, 400);
//   //   setTimeout(loadScript, 1000);
//   // }, []);

//   return (
//     <>
//       <div id="v-newstak-in-v1"></div>
//       <script async
//         data-cfasync="false"
//         id="vdo-ai-script"
//         dangerouslySetInnerHTML={{
//           __html: `(function(v,d,o,ai){ai=d.createElement('script');ai.defer=true;ai.async=true;ai.src=v.location.protocol+o;d.head.appendChild(ai);})(window, document, '//a.vdo.ai/core/v-newstak-in-v1/vdo.ai.js');`,
//         }}
//       ></script>
//     </>
//   );
// };

// export default VDOAdsScript;
